<style lang="less">
.cours_study_details_Notes {
  .el-dialog {
    .el-dialog__body {
      padding: 10px 20px 20px;
      .el-form {
        max-height: 450px;
        overflow-y: auto;
      }
      .el-form-item__label {
        font-weight: 500;
      }
      .el-form-item__content {
        img {
          width: 100%;
        }
      }
      ol,
      ul {
        margin-left: 18px;
      }
    }
  }
  ul {
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      background: #f2f2f2;
      padding: 4px 8px;
      border-radius: 4px;
      &:hover {
        .content {
          color: @mainColor;
        }
      }
      .content {
        cursor: pointer;
        flex: 1;
        font-size: 14px;
        // font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: flex;
        align-items: center;
        p,
        img {
          line-height: 40px;
          height: 40px;
        }
      }
    }
  }
}
</style>
<template>
  <div class="cours_study_details_Notes">
    <ul v-loading="loading">
      <li v-for="(note, i) in notes" :key="i">
        <div class="content" @click="edit(note)" v-html="note.noteTitle"></div>
        <div class="button">
          <el-button
            @click="edit(note)"
            type="text"
            icon="el-icon-edit"
          ></el-button>
          <el-button
            @click="del(note)"
            type="text"
            icon="el-icon-delete"
          ></el-button>
        </div>
      </li>
      <el-button @click="add" type="primary">添加</el-button>
    </ul>

    <el-dialog
      :visible.sync="dialog"
      width="50%"
      :title="`${rows ? '查看笔记' : '添加笔记'}`"
      :before-close="beforeClose"
    >
      <div class="dialog-content">
        <el-button
          v-if="rows"
          type="primary"
          style="margin-bottom: 10px"
          @click="edit_flag = !edit_flag"
          >编辑</el-button
        >
        <el-form :model="form" inline>
          <el-form-item :label="!edit_flag ? '标题' : ''" prop="noteTitle">
            <QuillEditor
              v-if="!edit_flag"
              @quillEditorHtml="(e) => (form.noteTitle = e)"
              style="margin-top: 0"
              :paramsText="form.noteTitle"
              @add-notes-title="addNotesTitle"
              resultMethods="add-notes-title"
            />
            <div v-else v-html="form.noteTitle"></div>
          </el-form-item>
          <br />
          <el-form-item :label="!edit_flag ? '内容' : ''" prop="noteInfo">
            <QuillEditor
              v-if="!edit_flag"
              @quillEditorHtml="(e) => (form.noteInfo = e)"
              style="margin-top: 0"
              :paramsText="form.noteInfo"
              @add-notes-info="addNotesInfo"
              resultMethods="add-notes-info"
            />
            <div v-else v-html="form.noteInfo"></div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFalse()">取 消</el-button>
        <el-button
          type="primary"
          :disabled="rows && edit_flag"
          :loading="btn_loading"
          @click="confirm"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  QueryEduCourseOutNoteById,
  DeleteEduCourseOutNote,
  UpdateEduCourseOutNote,
  InsertEduCourseOutNote,
} from "@/libs/api/course";
import QuillEditor from "@/components/teacher/QuillEditor";
export default {
  name: "cours_study_details_Notes",
  components: { QuillEditor },
  props: ["id"],
  data() {
    return {
      loading: false,
      btn_loading: false,
      notes: [], // 笔记列表
      dialog: false,
      edit_flag: false,
      rows: null, // 当前行数据
      form: {
        // outlineId: 1, //章节id
        noteTitle: "", //笔记标题
        noteInfo: "", //笔记内容
      }, // 表单 - 添加
    };
  },
  watch: {
    id(n) {
      this.init(n);
    },
  },
  mounted() {
    this.init(this.id);
  },
  methods: {
    init(id) {
      this.loading = true;
      QueryEduCourseOutNoteById({ outlineId: id })
        .then((res) => {
          this.loading = false;
          this.notes = res.data;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    add() {
      this.rows = null;
      this.dialog = true;
      this.edit_flag = false;
    },
    edit(row) {
      if (row) {
        this.rows = JSON.parse(JSON.stringify(row));
        this.edit_flag = true;
        this.form =  JSON.parse(JSON.stringify(row));
        this.dialog = true;
      }
    },
    del(row) {
      if (row) {
        this.$confirm("确定要删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.delService(row.id);
          })
          .catch(() => {});
      }
    },
    delService(id) {
      let data = new FormData();
      data.append("id", id);
      DeleteEduCourseOutNote(data).then((res) => {
        this.init(this.id);
        this.$message.success("删除成功");
      });
    },
    dialogFalse(){
      this.dialog = false;
      this.clear();
    },
    beforeClose(done){
      done();
      this.clear();
    },
    confirm() {
      if (null == this.form.noteTitle || this.form.noteTitle === ""){
        return this.$message.error("笔记标题不可为空")
      }
      if (null == this.form.noteInfo || this.form.noteInfo === ""){
        return this.$message.error("笔记内容不可为空")
      }
      const Service = this.rows
        ? UpdateEduCourseOutNote
        : InsertEduCourseOutNote;
      const msg = this.rows ? "编辑" : "添加";
      this.btn_loading = true;
      const data = {
        noteTitle: this.form.noteTitle, //笔记标题
        noteInfo: this.form.noteInfo, //笔记内容
      };
      if (this.rows) {
        data.insertEduCourseOutNote = this.rows.outlineId;
        data.id = this.rows.id;
      } else {
        data.outlineId = this.id;
      }
      Service(data)
        .then((res) => {
          this.btn_loading = false;
          this.$message.success(`${msg}成功`);
          this.init(this.id);
          this.clear();
          this.dialog = false;
        })
        .catch(() => {
          this.btn_loading = false;
        });
    },
    clear() {
      this.form = {
        noteTitle: "", //笔记标题
        noteInfo: "", //笔记内容
      };
      this.rows = null;
    },
    addNotesTitle(val) {
      if (val) {
        this.form.noteTitle += val;
      } else {
        this.form.noteTitle = val;
      }
    },
    addNotesInfo(val) {
      if (val) {
        this.form.noteInfo += val;
      } else {
        this.form.noteInfo = val;
      }
    },
  },
};
</script>