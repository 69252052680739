<template>
  <div class="stu_cours_study_details">
    <StudentHeader :night="true" :videoFlag="true" @isNight="isNight" />
    <div
      class="stu_cours_study_details_container"
      :class="night ? 'stu_cours_study_details_container_night' : ''"
    >
      <el-row class="group">
        <el-col :span="!switchs ? 18 : 24">
          <div class="stu-module-header">
            <div class="stu-module-title">
              {{ items ? items.chapterName : "-" }}
            </div>
            <div class="stu-cours-student-study-details-btn-group">
              <el-button @click="outStudy">退出学习</el-button>
              <i
                class="el-icon-s-unfold stu-switch-i"
                @click="handleSwitch"
              ></i>
            </div>
          </div>
          <template v-if="items">
            <!-- 播放器 -->
            <template v-if="nextTickVideo">
              <study-video
                ref="studyVideo"
                v-show="isTypeVideo(items)"
                :teachingUrl="teachingUrl"
                :items="items"
                @videoProgress="getVideoProgress"
              />
            </template>
            <el-image
              v-show="items.resourceType && items.resourceType.includes('图片')"
              :src="teachingUrl"
            ></el-image>
            <el-link
              v-show="isTypeNotVideo(items)"
              target="_blank"
              type="primary"
              :href="teachingUrl"
              >点击跳转至外部链接</el-link
            >
          </template>
        </el-col>
        <el-col v-if="!switchs" :span="6" class="stu_cours_study_details_nav">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="目录" name="nav">
              <video-nav
                @switchUrl="switchUrl"
                @idItem="idItem"
                :id="$route.query.id"
              />
            </el-tab-pane>
            <el-tab-pane label="问答" name="qa">暂未开发</el-tab-pane>
            <el-tab-pane label="笔记" name="notes">
              <VideoNotes v-if="chapterIndex" :id="chapterIndex" />
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import StudentHeader from "@/components/student/Header.vue";
import StudyVideo from "./module/StudyVideo.vue";
import VideoNav from "./module/Nav.vue";
import VideoNotes from "./module/Notes.vue";
import { KeepStuding, QuitLearning } from "@/libs/api/course.js";
import { dateTimeFormat } from "@/tools/date.js";
export default {
  name: "stu_cours_study_details",
  components: { StudentHeader, StudyVideo, VideoNav, VideoNotes },
  data() {
    return {
      switchs: false,

      activeName: "nav",
      night: false,
      items: null, // 继续学习接口 返回的信息
      teachingUrl: "",
      resourceDuration: 0, // 视频时长 秒
      videoStartSecond: 0, // 视频开始时间
      videoEndSecond: 0, // 视频结束时间
      browser: {
        name: "", //浏览器名字
        version: "", // 版本号
        system: "", // 操作系统
      },
      players: null,
      chapterIndex: null, // 切换视频的章节id
      nextTickVideo: true,
      videoProgress: {
        currentTime: 0, // 进度
        id: null, // 章节id
        courseId: null, // 课程id
        studentId: null, // 学生id
      },
    };
  },
  computed: {
    isTypeVideo() {
      return (items) => {
        const url = items.teachingUrl;
        if (!url) return;
        const hz = url.substr(url.length - 4, url.length);
        // 外部链接是视频
        if (
          items.teachingType === 3 &&
          items.resourceType &&
          items.resourceType === "外部链接" &&
          ".mp4".includes(hz)
        ) {
          return true;
        }
        return items.resourceType && "视频".includes(items.resourceType);
      };
    },
    isTypeNotVideo() {
      return (items) => {
        const url = items.teachingUrl;
        if (!url) return;
        const hz = url.substr(url.length - 4, url.length);
        return (
          items.resourceType &&
          items.resourceType.includes("外部链接") &&
          !".mp4".includes(hz)
        );
      };
    },
  },
  mounted() {
    // this.init();
    // 获取浏览器信息 版本号
    let browser = this.getBrowserInfo();
    if (browser.length) {
      browser = browser.join();
      this.browser.name = (browser + "").replace(/[0-9.\/]/gi, "");
      this.browser.version = (browser + "").replace(/[^0-9.]/gi, "");
    }

    // 浏览器关闭
    window.addEventListener("beforeunload", (e) =>
      this.getLocalVideoProgress(e)
    );
  },
  destroyed() {
    // 销毁
    window.removeEventListener("beforeunload", (e) =>
      this.getLocalVideoProgress(e)
    );
  },
  methods: {
    init() {
      // 学生端 继续学习
      const courseId = this.$route.query.courseId;
      courseId &&
        KeepStuding(courseId).then((res) => {
          this.chapterIndex = res.data.id; // 章节id
          this.items = res.data;
          this.items.studyStartTime = dateTimeFormat(new Date()); // 开始学习时间
          this.$nextTick(() => {
            this.teachingUrl = res.data.teachingUrl; // 视频路径
          });
        });
    },
    // 退出学习
    outStudy() {
      // 点击退出学习调用视频组件的暂停方法
      this.players && this.$refs["studyVideo"].watchVideo(this.players);
      if (this.items) {
        const { id, chapterName, chapterIndex, studyStartTime } = this.items;
        const { courseId, studentId } = this.$route.query;
        // 学习时长
        let studySecond = this.videoEndSecond - this.videoStartSecond;
        let data = new FormData();
        data.append("studentId", studentId); // 学生id
        data.append("courseId", courseId); // 课程id
        data.append("unitName", chapterName); // 单元名称
        data.append("videoStartSecond", this.videoStartSecond); // 视频开始时间
        data.append("videoEndSecond", this.videoEndSecond); // 视频结束时间
        data.append("studySecond", studySecond); // 学习时长
        data.append("studyStartTime", studyStartTime); // 开始学习时间
        data.append("studyEndTime", dateTimeFormat(new Date())); // 结束学习时间
        data.append("browser", this.browser.name); // 浏览器
        data.append("browserEdition", this.browser.version); // 浏览器版本
        data.append("operatingSystem", this.browser.system); // 操作系统
        data.append("chapterId", this.chapterIndex || id); // 章节id
        QuitLearning(data)
          .then((res) => {
            if (res.code && res.code === 2000) {
              this.$message.success("已退出当前学习");
            }
            // 获取本地并存入进度
            this.getLocalVideoProgress();

            this.$refs["studyVideo"].$refs["videoPlayer"].player &&
              this.$refs["studyVideo"].$refs["videoPlayer"].player.pause();
            // this.$message.success("已退出学习，即将关闭此窗口...");
            // setTimeout(() => {
            //   window.close(); // 关闭窗口
            // }, 1500);
          })
          .catch((error) => {
            const { message } = error;
            this.$message.error(message);
          });
      }
    },
    getLocalVideoProgress() {
      // 判断是否已记录
      if (this.videoProgress.id) {
        console.log(localStorage.VIDEO_PROGRESS_LIST);
        // 获取本地进度列表
        let result = localStorage.VIDEO_PROGRESS_LIST
          ? JSON.parse(localStorage.VIDEO_PROGRESS_LIST)
          : [];
        // 判断本地是否之前已经存入了有相同的id
        console.log(result);
        // const hasId = result.map((f) => f.id).includes(this.videoProgress.id);
        const hasId = result.find((f) => f.id === this.videoProgress.id);
        // 有相同的替换掉
        if (result.length && hasId) {
          const findIndex = result.findIndex((f) => f.id === hasId.id);
          result[findIndex] = this.videoProgress;
        } else {
          // 未有相同的则添加
          result.push({
            ...this.videoProgress,
          });
        }
        // 覆盖
        localStorage.setItem("VIDEO_PROGRESS_LIST", JSON.stringify(result));
        // 清空
        this.videoProgress = {
          currentTime: 0,
          id: null,
          studentId: null,
          courseId: null,
        };
      }
    },
    idItem(item) {
      if (item) {
        this.items = item;
        this.items.studyStartTime = dateTimeFormat(new Date()); // 开始学习时间
        this.chapterIndex = item.id; // 章节id
        this.teachingUrl = item.teachingUrl; // 视频路径
      } else {
        this.init();
      }
    },
    // 获取视频第一次点击播放开始时间
    getVideoProgress(player) {
      console.log(player);
      if (player) {
        const { cache_ } = player;
        this.players = player;
        // 视频进度
        if (cache_ && cache_.currentTime) {
          this.videoEndSecond = Math.floor(cache_.currentTime);
          // 保存进度
          const { id } = this.items;
          const { courseId, studentId } = this.$route.query;
          this.videoProgress = {
            currentTime: cache_.currentTime,
            id: id || null,
            studentId: studentId || null,
            courseId: courseId || null,
          };
        }
      }
    },
    // 切换视频
    switchUrl(item) {
      this.nextTickVideo = false;
      this.$nextTick(() => {
        this.items = item;
        this.items.studyStartTime = dateTimeFormat(new Date()); // 开始学习时间
        this.chapterIndex = item.id;
        this.teachingUrl = item.teachingUrl;
        this.nextTickVideo = true;
      });
    },
    handleSwitch() {
      this.switchs = !this.switchs;
    },
    handleClick(tab) {},
    // 夜间模式
    isNight(bool) {
      this.night = bool;
    },
    diffSeconds(startTime, endTime) {
      let start = new Date(startTime);
      let end = new Date(endTime);
      let seconds = (end.getTime() - start.getTime()) / 1000;
      return seconds;
    },
    // 时间差计算
    difference(beginTime, endTime) {
      var dateBegin = new Date(beginTime);
      var dateEnd = new Date(endTime);
      var dateDiff = dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数
      var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
      var leave1 = dateDiff % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000)); //计算出小时数
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000)); //计算相差分钟数
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);
      return minutes + "分" + seconds + "秒";
    },
    getBrowserInfo() {
      let userAgent = navigator.userAgent;
      let agent = userAgent.toLowerCase();
      // 操作系统
      if (userAgent.indexOf("Window") > 0) {
        this.browser.system = "windows";
      } else if (userAgent.indexOf("Mac OS X") > 0) {
        this.browser.system = "mac";
      } else if (userAgent.indexOf("Linux") > 0) {
        this.browser.system = "linux";
      } else {
        this.browser.system = "";
      }
      let regStr_ie = /msie [\d.]+;/gi;
      let regStr_ff = /firefox\/[\d.]+/gi;
      let regStr_chrome = /chrome\/[\d.]+/gi;
      let regStr_saf = /safari\/[\d.]+/gi;
      //IE
      if (agent.indexOf("msie") > 0) {
        return agent.match(regStr_ie);
      }

      //firefox
      if (agent.indexOf("firefox") > 0) {
        return agent.match(regStr_ff);
      }

      //Safari
      if (agent.indexOf("safari") > 0 && agent.indexOf("chrome") < 0) {
        return agent.match(regStr_saf);
      }

      //Chrome
      if (agent.indexOf("chrome") > 0) {
        return agent.match(regStr_chrome);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.stu_cours_study_details {
  .stu_cours_study_details_container {
    padding: 30px;
    height: calc(100vh - (@headerHeight + 60px));
    background: #f7f9ff;
    .group {
      height: calc(100vh - (@headerHeight + 60px));
      overflow-y: auto;
      background: #fff;
      box-shadow: @shadow;
      padding: 20px;
    }
  }
  @black1: #000;
  @black2: #222324;
  .stu_cours_study_details_container_night {
    background: @black1;
    .group {
      background: @black2;
      .stu-module-title {
        color: #898aba;
      }
      .stu-cours-student-study-details-btn-group {
        .el-button {
          background: @black1;
          border: 1px solid @black2;
        }
      }
    }
  }
}
</style>
<style lang="less">
.stu_cours_study_details {
  .stu_cours_study_details_container {
    .group {
      .stu-module-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        .stu-module-title {
          font-size: 22px;
          font-weight: bold;
        }
        .stu-cours-student-study-details-btn-group {
          display: flex;
          align-items: center;
          .stu-switch-i {
            margin-left: 15px;
            font-size: 22px;
          }
        }
      }
      .stu_cours_study_details_nav {
        .el-tabs {
          padding: 0px 20px;
          .el-tabs__item {
            font-size: 16px;
            color: #999;
            font-weight: bold;
            span {
              font-size: 16px;
              font-weight: bold;
              color: #999;
            }
          }
          .el-tabs__item.is-active {
            color: @fontColor;
            span {
              color: @fontColor;
            }
          }
          .el-tabs__active-bar {
            height: 4px;
          }
          .el-tabs__nav-wrap::after {
            background-color: #fff;
          }
        }
        .el-tabs-icon {
          .el-tabs__active-bar {
            height: 4px;
            width: 70px !important;
            margin-left: 15px;
          }
        }
      }
    }
  }
  .stu_cours_study_details_container_night {
    .group {
      .stu_cours_study_details_nav {
        .el-tabs {
          .el-tabs__item.is-active {
            color: @mainColor;
          }
          .el-tabs__nav-wrap::after {
            background: #222324;
          }
          .el-tabs__item {
            color: #f2f2f2;
          }
          .chapter_name {
            color: #f2f2f2;
          }
          .el-collapse-item__header {
            background: #000;
            p,
            i {
              color: #ccc;
            }
          }
          .el-collapse-item__content {
            background: #222324;

            .collapse_content_ul {
              .el-tag--small {
                color: #000;
                background: #f2f2f2;
                border: 1px solid #000;
              }
            }
            .collapse_content_li_name {
              color: #ccc;
            }
          }
        }
      }
    }
  }
}
</style>