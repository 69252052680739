<style lang="less">
.StudyVideo {
  max-height: calc(100vh - 230px) !important;

  .video-player {
    // height: 100%;
    .video-js.vjs-fluid,
    .video-js.vjs-16-9,
    .video-js.vjs-4-3 {
      //   height: 100%;
    }
  }

  .options {
    .title {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 20px;
    }

    ul {
      list-style: none;

      li {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
<template>
  <div class="StudyVideo">
    <video-player
      class="video-player vjs-custom-skin"
      ref="videoPlayer"
      :playsinline="true"
      :options="playerOptions"
      @play="play"
      @pause="pause"
      @ended="ended"
      @timeupdate="timeupdate"
      @ready="ready"
    ></video-player>

    <el-dialog
      title="即时题"
      :visible.sync="dialog"
      width="40%"
      :close-on-click-modal="false"
      :close-on-press-escap="false"
      :show-close="false"
    >
      <div class="options" v-if="rows">
        <div class="title">{{ rows.questionTitle }}</div>
        <ul v-if="rows.questionOps && rows.questionOps.length">
          <li v-for="(item, i) in rows.questionOps" :key="i">
            <el-radio
              v-model="radio"
              @change="radioChange(item)"
              :label="item.itemKey"
              >{{ item.itemVal }}
            </el-radio>
          </li>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="loading" @click="confirm"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { dateTimeFormat } from "@/tools/date";
import { CheckInstantQuestion } from "@/libs/api/course.js";
import settlement from "../../../../Online/settlement";

export default {
  name: "study-video",
  props: ["teachingUrl", "items"],
  data() {
    return {
      player: null,
      // 视频播放
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "",
            src: "", //url地址
          },
        ],
        poster: "", //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, //当前时间和持续时间的分隔符
          durationDisplay: true, //显示持续时间
          remainingTimeDisplay: false, //是否显示剩余时间功能
          fullscreenToggle: true, //全屏按钮
        },
      },
      loading: false,
      dialog: false,
      radio: null, // 选择答案
      question: null, // 选择的答案信息
      readTitle: [], // 已经弹出过的题目，不要继续弹出了
      rows: null, // 当前题目

      VIDEO_PROGRESS_LIST: localStorage.VIDEO_PROGRESS_LIST
        ? JSON.parse(localStorage.VIDEO_PROGRESS_LIST)
        : [],
    };
  },
  watch: {
    teachingUrl(n) {
      this.isVideo(n);
      this.readTitle = [];
      this.radio = null;
      this.question = null;
    },
  },
  computed: {
    answer() {
      return this.items.instantQuestions
        ? this.items.instantQuestions.map(
            (m) => m.timeStamp || m.timeStamp === 0
          )
        : [];
    },
  },
  mounted() {
    this.isVideo(this.teachingUrl);
  },
  methods: {
    isVideo(url) {
      if (url) {
        const hz = url.substr(url.length - 4, url.length);
        if (".mp4".includes(hz)) {
          this.playerOptions["sources"][0].src = url;
        }
      }
    },
    watchVideo(player) {
      this.pause(player);
    },
    radioChange(val) {
      if (val) this.question = val;
    },
    // 进度
    timeupdate(player) {
      if (!player) return;
      this.$emit("videoProgress", player);
      this.player = player;
      const len = this.answer.length;
      if (len) {
        for (let i = 0; i < len; i++) {
          const time = this.answer[i];
          if (
            parseInt(player.cache_.currentTime) === time &&
            !this.readTitle.includes(time)
          ) {
            this.$refs.videoPlayer.player.pause(); //暂停
            this.dialog = true;
            this.rows = this.items.instantQuestions[i];
            break;
          }
        }
      }
    },
    confirm(player) {
      if (!this.radio) return this.$message.error("请选择答案");
      this.loading = true;
      let data = new FormData();
      data.append("questionId", this.question.questionId);
      data.append("stuQuestionKey", this.question.itemKey);
      CheckInstantQuestion(data)
        .then((res) => {
          this.loading = false;
          if (res.data) {
            this.$message.success("回答正确");
            this.dialog = false;
            this.radio = null;
            this.question = null;
            this.$refs.videoPlayer.player.play(); // 继续
            this.readTitle.push(this.rows.timeStamp);
          } else {
            if (this.rows.isPlayback != null && this.rows.isPlayback == 1) {
              this.$message.error("回答错误");
              this.dialog = false;
              this.player.src(this.teachingUrl);
              this.player.play(); // 继续
            } else {
              this.$message.error("回答错误");
              this.dialog = false;
              this.readTitle.push(this.rows.timeStamp);
              this.player.play(); // 继续
            }
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    ready(player) {
      console.log(this.VIDEO_PROGRESS_LIST);
      // 进度恢复
      if (this.VIDEO_PROGRESS_LIST.length) {
        const { studentId } = this.$route.query;
        const item = this.VIDEO_PROGRESS_LIST.find(
          (f) => f.id === this.items.id && f.studentId === studentId
        );
        player.currentTime(item ? item.currentTime : 0);
      } else {
        player.currentTime(0);
      }
    },
    // 播放
    play(player) {
      this.$emit("videoProgress", player);
    },
    // 暂停
    pause(player) {
      this.$emit("videoProgress", player);
    },
    // 播放完毕
    ended(player) {
      this.$emit("videoProgress", player);
    },
  },
};
</script>
