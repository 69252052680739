<template>
  <div class="cours_study_details_Nav">
    <div class="chapter" v-for="(item, index) in data" :key="index">
      <div class="chapter_hedaer">
        <p class="chapter_name">{{ item.chapterName }}</p>
        <!-- <p class="chapter_percent">
          <i class="el-icon-time"></i>
          <span>学习进度：{{ item.percent }}%</span>
        </p> -->
      </div>
      <el-collapse
        v-model="item.activeName"
        @change="handleChange"
        class="chapter_collapse"
      >
        <template v-if="item.childList">
          <el-collapse-item :name="i" v-for="(c, i) in item.childList" :key="i">
            <template slot="title">
              <div class="collapse_header">
                <p>{{ c.chapterName }}</p>
              </div>
            </template>
            <div class="collapse_content">
              <ul
                class="collapse_content_ul"
                v-if="c.childList && c.childList.length"
              >
                <li v-for="(d, j) in c.childList" :key="j">
                  <p>
                    <el-tag type="plain" size="small">{{
                      d.resourceType
                    }}</el-tag>
                    <span
                      class="collapse_content_li_name"
                      @click="openVideo(d)"
                      >{{ d.chapterName }}</span
                    >
                  </p>
                </li>
              </ul>
            </div>
          </el-collapse-item>
        </template>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import { CourseStudyDetail } from "@/libs/api/course";
export default {
  name: "cours_study_details_Nav",
  props: ["id"],
  data() {
    return {
      data: [
        // {
        //   title: "第一章",
        //   percent: 0,
        //   activeName: "",
        //   id: "1",
        //   chapter: [
        //     {
        //       title: "概述（一）",
        //       percent: 70,
        //       id: "1-1",
        //       detail: [
        //         {
        //           tag: "视频",
        //           title: "概述（一）",
        //           percent: 40,
        //           url: "",
        //           id: "1-1-1",
        //         },
        //         {
        //           tag: "文档",
        //           title: "概述（二）",
        //           percent: 30,
        //           url: "",
        //           id: "1-1-2",
        //         },
        //       ],
        //     },
        //     {
        //       title: "概述（二）",
        //       percent: 50,
        //       id: "1-2",
        //       detail: [
        //         {
        //           tag: "视频",
        //           title: "概述（二）",
        //           percent: 20,
        //           url: "",
        //           id: "1-2-1",
        //         },
        //         {
        //           tag: "文档",
        //           title: "概述（二）",
        //           percent: 30,
        //           url: "",
        //           id: "1-2-2",
        //         },
        //       ],
        //     },
        //   ],
        // },
        // {
        //   title: "第二章",
        //   percent: 0,
        //   activeName: "",
        //   id: "2",
        //   chapter: [
        //     {
        //       title: "运算方法和运算器",
        //       percent: 70,
        //       id: "2-1",
        //       detail: [
        //         {
        //           tag: "视频",
        //           title: "运算方法和运算器（一）",
        //           percent: 40,
        //           url: "",
        //           id: "2-1-1",
        //         },
        //         {
        //           tag: "文档",
        //           title: "运算方法和运算器（二）",
        //           percent: 30,
        //           url: "",
        //           id: "2-1-2",
        //         },
        //       ],
        //     },
        //   ],
        // },
      ],
    };
  },
  mounted() {
    this.init();
    !this.id && this.$emit("idItem", null);
  },
  methods: {
    init() {
      const courseId = this.$route.query.courseId;
      CourseStudyDetail(courseId).then((res) => {
        this.data = res.data.detailList;
        this.data.forEach((item) => {
          if (item.childList && item.childList.length) {
            item.activeName = item.childList.map((m, i) => i);
            if (this.id) {
              item.childList.forEach((c) => {
                if (c.childList && c.childList.length) {
                  const findItem = c.childList.find(
                    (f) => f.id === Number(this.id)
                  );
                  if (findItem) {
                    this.$emit("idItem", findItem);
                  }
                }
              });
            }
          }
        });
      });
    },
    handleChange() {},
    openVideo(item) {
      if (item.teachingType === 2) {
        const id = item.workSetId;
        if (!id) return this.$message.error("缺少workSetId");
        sessionStorage.setItem("stuWorkBlank", JSON.stringify(item));
        let routeData = this.$router.resolve({
          path: "/work/student/answer/write",
          query: { id },
        });
        window.open(routeData.href, "_blank");
      } else {
        this.$emit("switchUrl", item);
      }
    },
  },
};
</script>

<style scoped lang='less'>
.cours_study_details_Nav {
  height: calc(100vh - (215px));
  overflow: auto;
  .chapter {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #eee;
    .chapter_hedaer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .chapter_name {
        font-size: 18px;
        padding-left: 10px;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 25%;
          height: 13px;
          width: 3px;
          border-radius: 2px;
          background: @mainColor;
        }
      }
      .chapter_percent {
        .el-icon-time {
          margin-right: 5px;
        }
        span {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
<style lang="less">
.cours_study_details_Nav {
  .chapter {
    .chapter_collapse {
      border-top: 0;
      .el-collapse-item {
        margin-top: 10px;
        .el-collapse-item__content {
          padding-bottom: 10px;
        }
      }
      .el-collapse-item__wrap {
        border-bottom: 0;
      }
      .el-collapse-item__header {
        background: #f1f6ff;
        border-bottom: 0;
        position: relative;
        .el-collapse-item__arrow {
          position: absolute;
          left: 10px;
          top: 18px;
        }
        .collapse_header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding-left: 35px;
          font-size: 16px;
        }
      }

      .collapse_content {
        .collapse_content_ul {
          padding: 0 30px;
          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #eee;
            padding: 15px 0;
            .collapse_content_li_name {
              display: inline-block;
              margin-left: 10px;
              color: #717883;
              cursor: pointer;
              &:hover {
                color: @mainColor;
              }
            }
          }
        }
      }
    }
  }
}
</style>